import "./App.css";
import Navbar from "./components/Navbar/Navbar";
import Home from "./views/Home/Home";
import Footer from "./components/Footer/Footer";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import WebDev from "./views/WebDev/WebDev";
import AppDev from "./views/AppDev/AppDev";
import Company from "./views/Company/Company";
import Login from "./views/Login/Login";
import Contact from "./views/Contact/Contact";
import Privacy from "./views/Privacy/Privacy";

function App() {
  return (
    <div className="App">
      <Router>
        <Navbar />
        <Routes>
          <Route exact path={"/"} element={<Home />} />
          <Route path={"/webdev"} element={<WebDev />} />
          <Route path={"/appdev"} element={<AppDev />} />
          <Route path={"/company"} element={<Company />} />
          <Route path={"/login"} element={<Login />} />
          <Route path={"/contact"} element={<Contact />} />
          <Route path={"/privacy"} element={<Privacy />} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
