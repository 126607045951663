import React from "react";
import {
  Box,
  chakra,
  Container,
  Link,
  SimpleGrid,
  Stack,
  Text,
  Flex,
  VisuallyHidden,
  Input,
  IconButton,
  useColorModeValue,
} from "@chakra-ui/react";

import { FaInstagram, FaTwitter, FaYoutube } from "react-icons/fa";
import { BiMailSend } from "react-icons/bi";
import Logo from "../../assets/Triacore-logo-new.png";

const SocialButton = ({ children, label, href }) => {
  return (
    <chakra.button
      bg={useColorModeValue("blackAlpha.100", "whiteAlpha.100")}
      rounded={"full"}
      w={8}
      h={8}
      cursor={"pointer"}
      as={"a"}
      href={href}
      display={"inline-flex"}
      alignItems={"center"}
      justifyContent={"center"}
      transition={"background 0.3s ease"}
      _hover={{
        bg: useColorModeValue("blackAlpha.200", "whiteAlpha.200"),
      }}
    >
      <VisuallyHidden>{label}</VisuallyHidden>
      {children}
    </chakra.button>
  );
};

const ListHeader = ({ children }) => {
  return (
    <Text fontWeight={"500"} fontSize={"lg"} mb={2}>
      {children}
    </Text>
  );
};

const Footer = () => {
  return (
    <Box
      bg={"#fff"}
      color={useColorModeValue("gray.700", "gray.200")}
      zIndex={-1}
    >
      <Flex
        w={"full"}
        h={{ base: "120vh", md: "500px" }}
        position={"absolute"}
        background={"#f6f9fc"}
        backgroundSize={"cover"}
        backgroundPosition={"center center"}
        clipPath={"polygon(0 40%, 100% 0, 100% 100%, 0 100%)"}
        zIndex={0}
      />
      <Container as={Stack} maxW={"6xl"} pt={200} pb={20}>
        <SimpleGrid
          templateColumns={{ sm: "1fr", md: "2fr 1fr 1fr 2fr" }}
          spacing={8}
          zIndex={1}
        >
          <Stack spacing={6}>
            <Box maxW={"120px"} margin={{ base: "auto", md: "0" }}>
              <img src={Logo} alt={"Triacore Logo"} />
            </Box>
            <Text
              fontSize={"sm"}
              textAlign={{ base: "center", sm: "center", lg: "left" }}
            >
              © {new Date().getFullYear()} Triacore. All rights reserved
            </Text>
            <Stack
              direction={"row"}
              spacing={6}
              display={"flex"}
              justify={{ base: "center", md: "left" }}
            >
              <SocialButton label={"Twitter"} href={"#"}>
                <FaTwitter />
              </SocialButton>
              <SocialButton label={"YouTube"} href={"#"}>
                <FaYoutube />
              </SocialButton>
              <SocialButton label={"Instagram"} href={"#"}>
                <FaInstagram />
              </SocialButton>
            </Stack>
          </Stack>
          <Stack textAlign={{ base: "center", sm: "center", lg: "left" }}>
            <ListHeader>Company</ListHeader>
            <Link href={"/company"}>About us</Link>
            <Link href={"/contact"}>Contact us</Link>
          </Stack>
          <Stack textAlign={{ base: "center", sm: "center", lg: "left" }}>
            <ListHeader>Support</ListHeader>
            <Link href={"/contact"}>Help Center</Link>
            <Link href={"/privacy"}>Privacy & Terms</Link>
          </Stack>
          <Stack textAlign={{ base: "center", sm: "center", lg: "left" }}>
            <ListHeader>Stay up to date</ListHeader>
            <Stack direction={"row"}>
              <Input
                placeholder={"Your email address"}
                bg={useColorModeValue("blackAlpha.100", "whiteAlpha.100")}
                border={0}
                _focus={{
                  bg: "whiteAlpha.300",
                }}
              />
              <IconButton
                bg={useColorModeValue("#0a2540")}
                color={useColorModeValue("white", "gray.800")}
                _hover={{
                  bg: "#635bff",
                }}
                aria-label="Subscribe"
                icon={<BiMailSend />}
              />
            </Stack>
          </Stack>
        </SimpleGrid>
      </Container>
    </Box>
  );
};

export default Footer;
