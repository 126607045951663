import React from "react";
import Feature from "../../components/Feature/Feature";
import Hero from "../../components/Hero/Hero";
import { heroDetails, webdev } from "../../data/data";
import { Box } from "@chakra-ui/react";
import CallToAction from "../../components/CalltoAction/CallToAction";

const WebDev = () => {
  return (
    <Box>
      <Hero items={heroDetails[0].web} />
      <Feature items={webdev[0]} />
      <CallToAction />
    </Box>
  );
};

export default WebDev;
