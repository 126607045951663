import { Box } from "@chakra-ui/react";
import React from "react";
import GridListWithHeading from "../../components/GridListWithHeading/GridListWithHeading";
import Hero from "../../components/Hero/Hero";
import { companyDetails, heroDetails } from "../../data/data";

const Company = () => {
  return (
    <Box>
      <Hero items={heroDetails[0].company} />
      <GridListWithHeading items={companyDetails} />
    </Box>
  );
};

export default Company;
