import React from "react";
import Hero from "../../components/Hero/Hero";
import { heroDetails } from "../../data/data";

const Privacy = () => {
  return (
    <div>
      <Hero items={heroDetails[0].privacy} />
      <p>
        This Privacy Policy includes important information about your personal
        data and we encourage you to read it carefully. We provide economic
        infrastructure for the internet. Businesses of all sizes use our
        software and services to accept payments and manage their businesses
        online. Triacore cares about the security and privacy of the personal
        data that is entrusted to us. This Privacy Policy (“Policy”) describes
        the “Personal Data” that we collect about you, how we use it, how we
        share it, your rights and choices, and how you can contact us about our
        privacy practices. This Policy also outlines your data subject rights,
        including the right to object to some uses of your Personal Data by us.
        Please visit the Triacore Privacy Center for more information about our
        privacy practices. “Triacore”, “we”, “our” or “us” means the Triacore
        entity responsible for the collection and use of personal data under
        this Privacy Policy. It differs depending on your country. Learn more.
        “Personal Data” means any information that relates to an identified or
        identifiable individual, and can include information about how you
        engage with our Services (e.g. device information, IP address).
        “Services” means the products and services that Triacore indicates are
        covered by this Policy, which may include Triacore-provided devices and
        apps. Our “Business Services” are Services provided by Triacore to
        entities (“Business Users”) who directly and indirectly provide us with
        “End Customer” Personal Data in connection with those Business Users’
        own business and activities. Our “End User Services” are those Services
        which Triacore directs to individuals (rather than entities) so that
        those individuals do business directly with Triacore. “Sites” means
        Triacore.com and the other websites that Triacore indicates are covered
        by this Policy. Collectively, we refer to Sites, Business Services and
        End User Services as “Services”. Depending on the context, “you” means
        End Customer, End User, Representative or Visitor: When you directly use
        an End User Service (such as when you sign up for Link, or make a
        payment to Triacore Climate in your personal capacity), for your
        personal use, we refer to you as an “End User.” When you do business
        with, or otherwise transact with, a Business User (typically a merchant
        using Triacore Checkout, e.g. when you buy a pair of shoes from a
        merchant that uses Triacore for payment processing) but are not directly
        doing business with Triacore, we refer to you as an “End Customer.” When
        you are acting on behalf of an existing or potential Business User (e.g.
        you are a founder of a company, or administering an account for a
        merchant who is a Business User), we refer to you as a “Representative.”
        When you visit a Site without being logged into a Triacore account or
        otherwise communicate with Triacore, we refer to you as a “Visitor.”
        (e.g. you send Triacore a message asking for more information because
        you are considering being a user of our products). Depending on the
        activity, Triacore acts as a “data controller” or “data processor” and
        for more information about this and on the Triacore entity that is
        responsible under this Policy, see here. 1. Personal data that we
        collect and how we use and share it 2. More ways we collect, use and
        share personal data 3. Legal bases for processing data 4. Your rights
        and choices 5. Security and retention 6. International data transfers 7.
        Updates and notifications 8. Jurisdiction-specific provisions 9. Contact
        us 1. Personal data that we collect and how we use and share it 1.1 End
        Users We provide End User Services where we do not act as a service
        provider to Businesses but instead provide the Services directly to you
        for your personal use (e.g. Link user). See here for further information
        on the legal bases which we rely on for using (processing) your Personal
        Data. Personal data that we collect about End Users Using Link or
        Connecting your Bank Account. Triacore offers you the opportunity to
        store your payment methods with Triacore so that you can conveniently
        use it across merchants who are our Business Users – we call this “Link”
        (formerly known as “Remember Me”). When you opt in to Link, you agree to
        let us store your payment method so that you can more readily make
        purchases through Link with Business Users of our payment processing
        Business Services (e.g. name, card number, cvc, and expiration date).
        When you choose to pay with Link, we will also collect your Transaction
        Data. Learn More. If you choose to share bank account information
        (including for use in Link) with us, Triacore will periodically collect
        and process your bank account information (e.g. account owner
        information, account balances, account number and details, account
        transactions and in some cases, credentials) until you ask us to
        disconnect your bank account. Learn More. Paying Triacore. If you are
        buying goods or services directly from Triacore, we receive Transaction
        Data. For example, when you make a payment to Triacore Climate, we will
        collect contact information, payment method information, information
        about that transaction and in some cases, shipping information. Other
        Services. We may offer other End User Services directly to you from time
        to time, including certain financial Services. Learn More for
        information about our collection and use of Personal Data. More. Please
        see below for information about additional types of Personal Data that
        we may collect, including about online activity. How we use and share
        personal data of End Users Services. We use your Personal Data to
        provide the End User Service to you, including security, delivery,
        support, personalization and messages related to the End User Service
        (e.g. communicating Policy updates and information about our Services).
        We also use your Personal Data for our fraud detection and mitigation
        Business Services and may share certain Personal Data as part of those
        Business Services with Business Users that you may seek to do business
        with. The use of this Personal Data is subject to the Business Users’
        privacy policies. Our Business Users. When you choose to connect your
        bank account with Triacore, you may also choose to share account
        information with certain Business Users that you do business with. These
        Business Users will have their own privacy policies which describe how
        they use that information. Transactions. For payment transactions with
        Link, End User Personal Data is shared with others to enable the
        transaction. For example, when you choose to use a payment method for
        the transaction with Triacore or with Link (e.g. credit card, debit
        card, buy now pay later, or direct debit), that payment method will
        receive transaction information that includes your Personal Data. Please
        review your payment method’s privacy policy to learn more. When you use
        Link, the merchant you choose to do business with will also receive
        Transaction Data that includes your Personal Data and with your
        permission, your bank account information. The merchant may share that
        Personal Data with others (see below regarding End Customers). Please
        review your merchant’s privacy policy for further information. Fraud
        Detection Services. We use your Personal Data collected across our
        Services (e.g. Triacore Radar) to detect and prevent fraud against us,
        our Business Users and financial partners, including to detect
        unauthorized log-ins using your online activity. We may provide Business
        Users (including card issuers and others involved in payment processing
        activities) that have requested our fraud Business Services with
        Personal Data about you (including your attempted transactions) so that
        they can assess the associated fraud risk with a transaction. You can
        learn more about how we may use technology to assess the fraud risk
        associated with an attempted transaction here. Advertising. We may use
        your Personal Data to assess your eligibility for and offer you other
        End User Services. With your permission or where allowed by law, we use
        and share End User Personal Data with others so that we may market our
        products and services to you, including through interest-based
        advertising where allowed by applicable law, including subject to any
        consent requirements. See our Cookie Policy. We do not share End User
        Personal Data with third parties for their marketing or advertising
        unless you give us or the third party permission to do so. We do not
        sell the data of End Users. More. Please see below for information about
        additional ways in which we may use and share your Personal Data. 1.2
        End Customers Triacore offers Business Services to our Business Users
        (e.g. in-person checkout or online checkout). When we are acting as a
        Business User’s service provider (or data processor), we will process
        Personal Data in accordance with the terms of our agreement with the
        Business User and the Business User’s lawful instructions. For example,
        we process payment transactions for a Business User because you bought a
        product from them. Business Users are responsible for making sure that
        their End Customer’s privacy rights are respected, including ensuring
        appropriate disclosures about data collection and use that happens in
        connection with their services. If you are an End Customer (e.g., a
        purchaser of a product at an online Business User site), please refer to
        the privacy policy or notice of the Business User for information
        regarding the Business User’s privacy practices, choices and controls,
        or contact the Business User directly. See here for further information
        on the legal bases which we rely on for using (processing) your Personal
        Data. a. Personal data that we collect about End Customers Transaction
        Data. If you are an End Customer, when you make payments to, get refunds
        from, begin a purchase, make a donation or otherwise transact with a
        Business User that uses us to provide payment processing Business
        Services, we will receive transaction data. The “Transaction Data” that
        we collect includes Personal Data, and may include the following: your
        name, email address, billing address, shipping address, payment method
        information (such as credit or debit card number, bank account
        information or payment card image selected by you), merchant and
        location, purchase amount, date of purchase, and in some cases, some
        information about what you have purchased and your phone number. We may
        also receive your transaction history with the Business User. Learn
        More. We may collect information typed into a checkout form, even if you
        choose not to complete the form or purchase with the Business User.
        Learn More. Identity/Verification Information. Triacore provides a
        verification and fraud Service that allows a Business User to verify
        Personal Data about you, such as your age (when purchasing age
        restricted goods) or your authorization to use a payment method. You
        will be asked to share Personal Data and we will collect the information
        that you share for this purpose, such as your government ID, your image,
        and Personal Data you input or that is apparent from the physical
        payment method (e.g. credit card image). We may compare this information
        with information about you we collect from Business Users, financial
        partners, business partners, identity verification services, publicly
        available sources, and third party service providers. Learn More. More.
        Please see below for information about additional types of Personal Data
        that we may collect, including about online activity. b. How we use and
        share personal data of End Customers We generally use and share Personal
        Data of End Customers with Business Users to provide Business Services
        as described below, as well as for Triacore’s own purposes to secure,
        improve and provide our Business Services, as described below. Payments.
        We use your Transaction Data to provide our Payments related Business
        Services to Business Users, including to process online payment
        transactions, to calculate applicable sales tax, to invoice and bill,
        and to calculate their revenue. Learn More. We may also use Personal
        Data to provide and improve our Business Services. For payment
        transactions, your Personal Data is shared with a number of parties in
        connection with your transaction. Because we act as a service provider
        or processor, we share Personal Data to enable the transaction. For
        example, when you choose to use a payment method for the transaction
        (e.g. credit card, debit card, buy now pay later, or direct debit), your
        payment method will receive the Transaction Data that includes your
        Personal Data. Please review your payment method’s privacy policy to
        learn more about how they use and share this information. The merchant
        you choose to do business with will also receive Transaction Data that
        includes your Personal Data and the merchant may share that Personal
        Data with others. Please review your merchant’s privacy policy to learn
        more. Other Financial Services. Some of our Business Users use our
        Services in order to offer financial services to you, through Triacore
        or its financial partners. For example, they may provide a card product
        that enables you to purchase goods and services. These cards may carry
        the Triacore brand, bank partner brand and/or the brands of Business
        Users. In addition to any Transaction Data we may produce or receive
        when these cards are used for purchases, we will also receive and use
        your Personal Data in order to provide and manage these products. Please
        also see the privacy policies of the Business User and our bank partner,
        if applicable, associated with the financial service (whose brands may
        be shown on the card). Identity/Verification Services. We use Personal
        Data about your identity, including information provided by you and our
        service providers, to perform verification Services for Triacore or for
        the Business Users that you are doing business with and to reduce fraud
        and enhance security. If you provide a “selfie” along with an image of
        your identity document, we will use technology to compare and calculate
        whether they match and you can be verified. Learn More. Fraud Detection
        Services. We use your Personal Data collected across our Services to
        detect and prevent fraud against us, our Business Users and financial
        partners (e.g. Triacore Radar), including to detect unauthorized log-ins
        using your online activity. We may provide Business Users (including
        card issuers and others involved in payment processing activities) that
        have requested our fraud Business Services with Personal Data about you
        (including your attempted transactions) so that they can assess the
        associated fraud risk with a transaction. You can learn more about how
        we may use technology to assess the fraud risk associated with an
        attempted transaction and what information we may share with Business
        Users about such risks here and here. Our Business Users (their
        Authorized Third Parties). We share Personal Data of End Customers with
        their respective Business Users and with parties directly authorized by
        those Business Users to receive Personal Data. This includes sharing
        Personal Data of End Customers with Business Users when a Business User
        authorizes a third party application provider to access its Triacore
        account using Triacore Connect. For example, when the Business User uses
        Identity Services to verify an End Customer’s identity, Triacore shares
        with the Business User the information, documents or photos provided by
        the End Customer to verify their identity. The Business Users you choose
        to do business with may further share your Personal Data to third
        parties they authorize. Please review your merchant’s privacy policy to
        learn more. Advertising by Business Users. If you have begun a purchase,
        we share Personal Data with that Business User in connection with our
        provision of Services and that Business User may use your Personal Data
        to market and advertise their products or services. Please review your
        merchant’s privacy policy to learn more, including your rights to stop
        their use of your Personal Data for marketing purposes. We do not use or
        share End Customer Personal Data for our marketing or advertising, or
        for marketing and advertising by third parties who are not the Business
        User with which you have transacted or attempted to transact. We do not
        sell the data of End Customers. More. Please see below for information
        about additional ways in which we may use your Personal Data. 1.3
        Representatives To provide Business Services, we collect, use and share
        Personal Information from Representatives of our Business Users (e.g. a
        business owner). If the Representative is the only employee of a
        Business User, please see the End User and End Customer sections to
        understand additional ways in which we can collect and use your Personal
        Data when you use our Services. See here for further information on the
        legal bases which we rely on for using (processing) your Personal Data.
        a. Personal data that we collect about Representatives Registration and
        Contact Information. If you register for a Triacore account for a
        Business User (including incorporation of a Business), we collect your
        name and account log-in credentials. If you register for an event that
        Triacore organizes or attends or if you sign up for Triacore
        communications, we collect your registration and profile information. If
        you are a Representative of a potential Business User, we receive your
        Personal Data from third parties (including data providers) in order to
        advertise to, market and communicate with you as described further below
        and in Section 2. Identification Information. If you are an owner of a
        Business User or you are expected to be a shareholder, officer or
        director of a Business User, we require that you provide your contact
        details, such as name, postal address, telephone number, and email
        address to fulfill our financial partner and regulatory requirements. We
        may also collect financial and personal information about you, such as
        your ownership interest in the Business User, your date of birth and
        government identifiers associated with you and your Business User (such
        as your social security number, tax number, or Employer Identification
        Number). You may also choose to provide bank account information. More.
        Please see below for information about additional types of Personal Data
        that we may collect, including about online activity. b. How we use and
        share personal data of Representatives We generally use Personal Data of
        Representatives to provide the Business Services to the associated
        Business Users, as well as for the purposes described below. Business
        Services. We use and share Personal Data of Representatives with
        Business Users to provide the Services. For users of our tax Business
        Services, we may use your Personal Data to file taxes on behalf of your
        associated Business User. If your Business User uses Atlas, we may use
        your Personal Data to submit forms to the IRS on your behalf and to file
        documents with other governmental authorities (e.g. articles of
        incorporation in your state of incorporation). We share data with
        parties directly authorized by a Business User to receive Personal Data
        (e.g. financial partners servicing the financial product). The use of
        Personal Data by a Business User’s authorized third party is subject to
        the third party’s privacy policy. In some cases our Business Service
        will require us to submit your Personal Data to a government entity
        (e.g. incorporating a business, or paying applicable sales tax).
        Advertising. With your permission or where allowed by applicable law, we
        use and share Representative Personal Data with others so that we may
        advertise and market our products and services to you, including through
        interest-based advertising subject to any consent requirements under
        applicable law. See our Cookie Policy. We do not sell Representative
        Personal Data. More. Please see below for information about additional
        ways in which we may use and share your Personal Data. 1.4 Visitors
        (e.g. visitors to Triacore sites who are not an End User, End Customer
        or Representative) See here for further information on the legal bases
        which we rely on for using (processing) your Personal Data. a. Visitor
        personal data that we collect When you visit our Sites, we generally
        receive your Personal Data either from you providing it to us or through
        our use of cookies and similar technologies. See our Cookie Policy.
        Forms. When you choose to fill in a form on the Site or on third party
        websites featuring our advertising (e.g. LinkedIn or Facebook), we will
        collect the information included in the form, usually your contact
        information and other information about your question related to our
        Services. More. Please see below for information about additional types
        of Personal Data that we may collect, including about online activity.
        b. How we use and share visitor personal data Personalization. We use
        information about you that we gather from cookies and similar
        technologies to measure engagement with the content on the Sites, to
        improve relevancy and navigation, to personalize your experience and to
        tailor content about Triacore and our Services to you. Advertising. With
        your permission or where allowed by law, we use and share Visitor
        Personal Data with others so that we may advertise and market our
        products and services to you, including through interest-based
        advertising where allowed by applicable law, including subject to any
        consent requirements. See our Cookie Policy. More. Please see below for
        information about additional ways in which we may use your Personal
        Data. 2. More ways we collect, use and share personal data In addition
        to the ways we collect, use and share Personal Data that are described
        above, we also process your Personal Data as follows. a. Personal Data
        Collection Online Activity. Depending on the Service you use and the
        Business Users’ implementation of our Business Services, we will collect
        information about: Devices and browsers across our Sites and third-party
        websites, apps and other online services (“Third-Party Sites”), Usage
        data associated with those devices and browsers, including IP address,
        plug-ins, language used, time spent on Sites and Third-Party Sites,
        pages visited, links clicked, and the pages that led or referred you to
        Sites and Third-Party Sites. For example, activity indicators, like
        mouse activity indicators, help us detect fraud. Learn More. Please also
        see our Cookie Policy. Communication and Engagement Information. We will
        collect any information you choose to provide to us, for example,
        through support tickets, emails or social media. When you respond to
        Triacore emails or surveys, we collect your email address, name and any
        other information you choose to include in the body of your email or
        responses. If you contact us by phone, we will collect the phone number
        you use to call Triacore, as well as other information you may provide
        during the call. We will also collect your engagement data such as your
        registration for, attendance of, or viewing of Triacore events and other
        interaction with Triacore personnel. Forums and Discussion Groups. Where
        our Sites allow you to post content, we will collect Personal Data that
        you provide in connection with the post. b. Personal Data Usage. In
        addition to the ways described above in which we use Personal Data, we
        use Personal Data in the following ways: Improving and Developing our
        Services. We use analytics on our Sites to help us analyze your use of
        our Sites and Services and diagnose technical issues. To learn more
        about the cookies that may be served through our Sites and how you can
        control our use of cookies and third-party analytics, please see our
        Cookie Policy. We also collect and process Personal Data through our
        different Services, whether you are an End User, End Customer,
        Representative or Visitor, to improve our Services, develop new Services
        and support our efforts to make our Services more relevant and more
        useful to you. Communications. We will use the contact information we
        have about you to perform the Services, which may include sending codes
        via SMS to authenticate you. Learn More. If you are an End User,
        Representative or Visitor, we may communicate with you using the contact
        information we have about you (e.g. using email, phone, text message or
        videoconference) to provide information about our Services and our
        affiliates’ services, invite you to participate in our events or
        surveys, or otherwise communicate with you for our marketing purposes,
        provided that we do so in accordance with applicable law, including any
        consent or opt-out requirements. For example, when you submit your
        contact information to us or when we collect your business contact
        details through our participation at trade shows or other events, we may
        use the information to follow-up with you regarding an event, send you
        information that you have requested on our products and services and
        include you on our marketing information campaigns. Social Media and
        Promotions. If you choose to submit Personal Data to us to participate
        in an offer, program or promotion, we will use the Personal Data you
        submit to administer the offer, program or promotion. Based on your
        permission or opt-out, we will also use that Personal Data and Personal
        Data you make available on social media to market to you. Fraud
        Prevention and Security. We collect and use Personal Data to help us to
        detect and manage the activity of fraudulent and other bad actors across
        our Services, to enable our fraud detection Business Services, and to
        otherwise seek to secure our Services and transactions against
        unauthorized access, use, modification or misappropriation of Personal
        Data, information and funds. In connection with fraud and security
        monitoring, prevention, detection, and compliance activities for
        Triacore and its Business Users, we receive information from service
        providers (including credit bureaus), third parties, and the Services we
        provide. We may collect information from you, and about you, from
        Business Users, financial parties and in some cases third parties. For
        example, to protect our Services, we may receive information from third
        parties about IP addresses that malicious actors have compromised. Learn
        More. This Personal Data (e.g. name, address, phone number, country)
        helps us to confirm identities, run credit checks subject to applicable
        law and prevent fraud. We may also use technology to assess the fraud
        risk associated with an attempted transaction by an End Customer or End
        User with a Business User or financial partner. Compliance with Legal
        Obligations. We use Personal Data to meet our contractual and legal
        obligations related to anti-money laundering, Know-Your-Customer ("KYC")
        laws, anti-terrorism, export control and prohibitions on doing business
        with restricted persons or in certain business areas, and other legal
        obligations. We strive to make our Services safe, secure and compliant,
        and the collection and use of Personal Data is critical to this effort.
        For example, we may monitor patterns of payment transactions and other
        online signals and use those insights to reduce the risk of fraud, money
        laundering and other activity that is harmful to Triacore, our End Users
        and their End Customers. Minors. The Services are not directed to
        minors, including children under the age of 13, and we request that they
        do not provide Personal Data through the Services. In some countries, we
        may impose higher age limits as required by applicable law. We do not
        sell any Personal Data of End Customers, Representatives, Visitors or
        End Users, including those aged between 13 to 16. c. Personal Data
        Sharing. In addition to the ways described above, we share Personal Data
        in the following ways: Triacore Affiliates. We share Personal Data with
        other Triacore affiliated entities. When we share with these entities,
        it is for purposes identified in this Policy. Service Providers or
        Processors. In order to provide Services to our Business Users and End
        Users and to communicate, market and advertise to Visitors,
        Representatives and End Users regarding our Services, we will rely on
        others to provide us services. Service providers (provide a variety of
        critical services, such as hosting (storing and delivering), analytics
        to assess the speed, accuracy and/or security of our Services, identity
        verification, customer service, email and auditing. We authorize such
        service providers to use or disclose the Personal Data of our Users that
        we make available to perform services on our behalf and to comply with
        applicable legal requirements. We require such service providers to
        contractually commit to protect the security and confidentiality of
        Personal Data they process on our behalf. Our service providers are
        predominantly located in the European Union, the United States of
        America and India. Learn More. Financial Partners. “Financial Partners”
        are financial institutions that we partner with to offer the Services
        (including payment method acquirers, banks and payout providers). We
        share Personal Data of our Users with certain Financial Partners to
        provide the Services to the associated Business Users and to offer
        certain Services in partnership with our Financial Partners. For
        example, we share certain Personal Data of Representatives (e.g. loan
        repayment data and contact information) with institutional investors who
        purchase the Capital loans that we have made to the associated Business
        Users. Others with Consent. In some cases we may not provide a service,
        but instead refer you to, or enable you to engage with, others to get
        services (e.g. professional services firms that we partner with to
        deliver Atlas). In these cases, we will disclose the identity of the
        third party and the information that will be shared with them, and seek
        your consent to share the information. Corporate Transactions. In the
        event that we enter into, or intend to enter into, a transaction that
        alters the structure of our business, such as a reorganization, merger,
        sale, joint venture, assignment, transfer, change of control, or other
        disposition of all or any portion of our business, assets or stock, we
        may share Personal Data with third parties in connection with such
        transaction. Any other entity which buys us or part of our business will
        have the right to continue to use your Personal Data, but subject to the
        terms of this Policy. Compliance and Harm Prevention. We share Personal
        Data as we believe necessary: (i) to comply with applicable law, (ii) to
        comply with rules imposed by payment method in connection with use of
        that payment method (e.g. network rules for Visa); (iii) to enforce our
        contractual rights; (iv) to secure or protect the Services, rights,
        privacy, safety and property of Triacore, you or others, including
        against other malicious or fraudulent activity and security incidents;
        and (v) to respond to valid legal process requests from courts, law
        enforcement agencies, regulatory agencies, and other public and
        government authorities, which may include authorities outside your
        country of residence. 3. Legal bases for processing data For the
        purposes of the General Data Protection Regulation, we rely upon a
        number of legal bases to enable our processing of your Personal Data.
        For more information see here. a. Contractual and Pre-Contractual
        Business Relationships. We process Personal Data for the purpose of
        entering into business relationships with prospective Business Users and
        End Users and to perform the respective contractual obligations that we
        have with these Users. Activities include: Creation and management of
        Triacore accounts and Triacore account credentials, including the
        evaluation of applications to commence or expand the use of our
        Services; Creation and management of Triacore Checkout accounts;
        Accounting, auditing, and billing activities; and Processing of
        payments, including fraud detection and prevention, optimizing valid
        transactions, communications regarding such payments, and related
        customer service. b. Legal Compliance. We process Personal Data to
        verify the identity of our Users in order to comply with fraud
        monitoring, prevention and detection obligations, laws associated with
        the identification and reporting of illegal and illicit activity, such
        as "Anti-Money Laundering ("AML") and Know-Your-Customer ("KYC")"
        obligations, and financial reporting obligations. For example, we may be
        required to record and verify a User’s identity for the purpose of
        compliance with legislation intended to prevent money laundering and
        financial crimes. These obligations are imposed on us by the operation
        of law, industry standards, and by our financial partners, and may
        require us to report our compliance to third parties, and to submit to
        third party verification audits. c. Legitimate Business Interests. Where
        allowed under applicable law, we rely on our legitimate business
        interests to process Personal Data about you. The following list sets
        out the business purposes for which we have a legitimate interest in
        processing your data: Detect, monitor and prevent fraud and unauthorized
        payment transactions; Mitigate financial loss, claims, liabilities or
        other harm to End Customers, End Users, Business Users and Triacore;
        Determine eligibility for and offer new Triacore products and services
        Learn More; Respond to enquiries, send Service notices and provide
        customer support; Promote, analyze, modify and improve our Services,
        systems, and tools, and develop new products and services, including
        reliability of the Services; Manage, operate and improve the performance
        of our Sites and Services by understanding their effectiveness and
        optimizing our digital assets; Analyze and advertise our Services;
        Conduct aggregate analysis and develop business intelligence that enable
        us to operate, protect, make informed decisions, and report on the
        performance of, our business; Share Personal Data with third party
        service providers that provide services on our behalf and business
        partners which help us operate and improve our business Learn More;
        Enable network and information security throughout Triacore and our
        Services; and Share Personal Data among our affiliates. d. Consent. We
        may rely on consent to collect and process Personal Data as it relates
        to how we communicate with you and for the provision of our Services
        like Link, Atlas and Identity. When we process data based on your
        consent, you have the right to withdraw your consent at any time without
        affecting the lawfulness of processing based on such consent before the
        consent is withdrawn. 4. Your rights and choices You may have choices
        regarding our collection, use and disclosure of your Personal Data: a.
        Opting out of receiving electronic communications from us If you no
        longer want to receive marketing-related emails from us, you may opt-out
        via the unsubscribe link included in such emails or as described here.
        We will try to comply with your request(s) as soon as reasonably
        practicable. Please note that if you opt-out of receiving
        marketing-related emails from us, our Business Users may still send you
        messages and direct us to send you messages on their behalf. b. Your
        data protection rights Depending on your location and subject to
        applicable law, you may have the following rights described here with
        regard to the Personal Data we control about you: The right to request
        confirmation of whether Triacore processes Personal Data relating to
        you, and if so, to request a copy of that Personal Data; The right to
        request that Triacore rectifies or updates your Personal Data that is
        inaccurate, incomplete or outdated; The right to request that Triacore
        erase your Personal Data in certain circumstances provided by law. Learn
        more; The right to request that Triacore restrict the use of your
        Personal Data in certain circumstances, such as while Triacore considers
        another request that you have submitted (including a request that
        Triacore make an update to your Personal Data); The right to request
        that we export your Personal Data that we hold to another company, where
        technically feasible; Where the processing of your Personal Data is
        based on your previously given consent, you have the right to withdraw
        your consent at any time; and/or Where we process your information based
        on our legitimate interests, you may also have the right to object to
        the processing of your Personal Data. Unless we have compelling
        legitimate grounds or where it is needed for legal reasons, we will
        cease processing your information when you object. c. Process for
        exercising your data protection rights To exercise your data protection
        rights please also see the Triacore Privacy Center or contact us as
        described below. 5. Security and retention We make reasonable efforts to
        provide a level of security appropriate to the risk associated with the
        processing of your Personal Data. We maintain organizational, technical
        and administrative measures designed to protect Personal Data covered by
        this Policy against unauthorized access, destruction, loss, alteration
        or misuse. Personal Data is only accessed by a limited number of
        personnel who need access to the information to perform their duties.
        Unfortunately, no data transmission or storage system can be guaranteed
        to be 100% secure. To help us protect personal data, we encourage you to
        use a strong password and never share your password with anyone or use
        the same password with other sites or accounts. If you have reason to
        believe that your interaction with us is no longer secure (e.g. you feel
        that the security of your account has been compromised), please contact
        us immediately. Learn More. We retain your Personal Data as long as we
        are providing the Services to you or our Business Users (as applicable)
        or for a period during which we reasonably anticipate providing the
        Services. Even after we stop providing Services directly to you or a
        Business User with which you are doing business, and even if you close
        your Triacore account or complete a transaction with a Business User, we
        retain your Personal Data in order to comply with our legal and
        regulatory obligations. We may also retain it to allow for fraud
        monitoring, detection and prevention activities. We also keep Personal
        Data to comply with our tax, accounting, and financial reporting
        obligations, where we are required to retain the data by our contractual
        commitments to our financial partners, and where data retention is
        mandated by the payment methods you used. In cases where we keep
        Personal Data, we do so in accordance with any limitation periods and
        records retention obligations that are imposed by applicable law. Learn
        More. 6. International data transfers We are a global business. Personal
        Data may be stored and processed in any country where we do business,
        where our service providers do business or if you use an international
        payment method or financial partner service, the countries in which that
        payment method or financial partner operates. We may transfer your
        Personal Data to countries other than your own country, including to the
        United States. These countries may have data protection rules that are
        different from your country. When transferring data across borders, we
        take measures to comply with applicable data protection laws related to
        such transfer. In certain situations, we may be required to disclose
        Personal Data in response to lawful requests from Officials (such as law
        enforcement or security authorities). If you are located in the European
        Economic Area (“EEA”), the "United Kingdom ("UK")" or Switzerland,
        please see Triacore Privacy Center for more information. Where
        applicable law requires a data transfer mechanism, we use one or more of
        the following: EU Standard Contractual Clauses with a data recipient
        outside the EEA, Switzerland or the UK, verification that the recipient
        has implemented Binding Corporate Rules, or other legal methods
        available to us under applicable law. For transfers to third countries
        we have entered into Standard Contractual Clauses, approved by the
        European Commission, to ensure an adequate level of protection for the
        transfer of your Personal Data to those entities outside the EEA. You
        can obtain a copy of the relevant Standard Contractual Clauses. Learn
        More. While Triacore Inc. remains self-certified under the E.U.-U.S.
        Privacy Shield and the Swiss-U.S. Privacy Shield, it is not currently
        relying on these frameworks for the transfer of personal data to the
        U.S. For more information, please see Triacore Privacy Center. 7.
        Updates and notifications We may change this Policy from time to time to
        reflect new services, changes in our privacy practices or relevant laws.
        The “Last updated” legend at the top of this Policy indicates when this
        Policy was last revised. Any changes are effective when we post the
        revised Policy on the Services. We may provide you with disclosures and
        alerts regarding the Policy or Personal Data collected by posting them
        on our website and, if you are an End User or Business User, by
        contacting you through your Triacore Dashboard, email address and/or the
        physical address listed in your Triacore account. If applicable law
        requires that we provide notice in a specified manner prior to making
        any changes to this Policy applicable to you, we will provide such
        required notice. 8. Jurisdiction-specific provisions Australia. If you
        are an Australian resident, and you are dissatisfied with our handling
        of any complaint you raise under this Policy, you may wish to contact
        the Office of the Australian Information Commissioner. EEA and UK. To
        exercise your rights, you may contact our DPO. If you are a resident of
        the EEA or we have identified Triacore Payments Europe Limited as your
        data controller, and believe we process your information within the
        scope of the General Data Protection Regulation (GDPR), you may direct
        your questions or complaints to the Irish Data Protection Commission. If
        you are a resident of the UK, you may direct your questions or concerns
        to the UK Information Commissioner’s Office. Where Personal Data is used
        for regulated financial activities in Europe, Triacore Payments Europe
        Limited and Triacore local regulated entities (defined as those who are
        licensed, authorized or registered by a Local Regulatory Authority) are
        considered joint controllers. Learn More. Indonesia. As used in this
        Policy, “applicable law” includes Law No. 11 of 2008 as amended by Law
        No. 19 of 2016 on Electronic Information and Transactions, Government
        Regulation No. 71 of 2019 on the Implementation of Electronic Systems
        and Transactions, and Minister of Communication and Informatics
        Regulation No. 20 of 2016 on Personal Data Protection in Electronic
        Systems and “Personal Data” includes “personal data” as defined under
        such laws. Malaysia. If you have any questions or complaints about this
        Policy, please contact our DPO. Thailand. Thailand residents may have
        additional rights under applicable laws. If we process your Personal
        Data due to a legal obligation or contractual right, and you do not
        provide us with personal Information, we may not be able to lawfully
        provide you services. United States - California. If you are a consumer
        located in California, we process your personal information in
        accordance with the California Consumer Privacy Act ("CCPA"). You have a
        right to receive notice of our practices at or before collection of
        personal information. This section provides additional details about the
        personal information we collect and use for purposes of CCPA. How We
        Collect, Use, and Disclose your Personal Information. The Personal Data
        We Collect section further describes the personal information we may
        have collected about you, including the categories of sources of that
        information. We collect this information for the purposes described in
        the How We Use Personal Data section. We share this information as
        described in the How We Disclose Personal Data section. For specific
        details, please see here. Triacore uses cookies, including advertising
        cookies, as described in our Cookie Policy. Your CCPA Rights and
        Choices. As a California consumer and subject to certain limitations
        under the CCPA, you have choices regarding our use and disclosure of
        your personal information (learn more): Exercising the right to know:
        you may request that we disclose to you the personal information we have
        collected about you. You also have a right to request additional
        information about categories of their personal information collected,
        sold, or disclosed; purposes for which this personal information was
        collected or sold; categories of sources of personal information; and
        categories of third parties with whom we disclosed this personal
        information. Exercising the right to delete: you may request that we
        delete the personal information we have collected from you, subject to
        certain limitations under applicable law. Exercising the right to
        opt-out from a sale: We do not sell Personal Data as defined by the CCPA
        and have not done so in the past 12 months. Non-discrimination: The CCPA
        provides that you may not be discriminated against for exercising these
        rights. To submit a request to exercise any of the rights described
        above, please contact us using the methods described in the Contact Us
        section below. You may designate, in writing or through a power of
        attorney, an authorized agent to make requests on your behalf to
        exercise your rights under the CCPA. Before accepting such a request
        from an agent, we will require the agent to provide proof you have
        authorized it to act on your behalf, and we may need you to verify your
        identity directly with us. Further, to provide or delete specific pieces
        of personal information we will need to verify your identity to the
        degree of certainty required by law. We will verify your request by
        asking you to send it from the email address associated with your
        account or requiring you to provide information necessary to verify your
        account. An authorized agent may submit a request on your behalf by
        contacting us using the methods described in the Contact Us section
        below. We may still require you to directly verify your identity and
        confirm that you provided the authorized agent permission to submit the
        request. 9. Contact us If you have any questions or complaints about
        this Policy, please contact us. If you are an End Customer (i.e. an
        individual doing business or transacting with a Business User), please
        refer to the privacy policy or notice of the Business User for
        information regarding the Business User’s privacy practices, choices and
        controls, or contact the Business User directly.
      </p>
    </div>
  );
};

export default Privacy;
