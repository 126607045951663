export const NAV_ITEMS = [
  {
    label: "Solutions",
    children: [
      {
        label: "Web Development",
        subLabel: "Provide your visitors a unique experience",
        href: "/webdev",
      },
      {
        label: "App Development",
        subLabel: "Native or hybrid app for your business",
        href: "/appdev",
      },
    ],
  },
  {
    label: "Company",
    href: "/company",
  },
  {
    label: "Contact",
    href: "/contact",
  },
];
