import React, { useState, useRef } from "react";
import {
  Box,
  Flex,
  Spacer,
  HStack,
  IconButton,
  Stack,
  Collapse,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import { HamburgerIcon, CloseIcon } from "@chakra-ui/icons";
import DesktopNav from "./Desktop/DesktopNav";
import MobileNav from "./Mobile/Mobile";
import Logo from "../../assets/Triacore-logo-white.png";
import { Link } from "react-router-dom";

const Navbar = () => {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <Box
      position={"absolute"}
      marginLeft={"auto"}
      marginRight={"auto"}
      left={0}
      right={0}
    >
      <Flex
        bg={"transparent"}
        color={useColorModeValue("gray.600", "white")}
        minH={"60px"}
        align={"center"}
        maxW={"6xl"}
        margin={"auto"}
      >
        <Flex
          flex={{ base: 1, md: "auto" }}
          ml={{ base: -2 }}
          display={{ base: "flex", md: "none" }}
        >
          <IconButton
            onClick={onToggle}
            icon={
              isOpen ? <CloseIcon w={3} h={3} /> : <HamburgerIcon w={5} h={5} />
            }
            variant={"ghost"}
            aria-label={"Toggle Navigation"}
            color={"white"}
          />
        </Flex>
        <Flex flex={{ base: 1 }} justify={{ base: "center", md: "start" }}>
          <Box maxW={"120px"}>
            <Link to={"/"}>
              <img src={Logo} alt={"Triacore Logo"} />
            </Link>
          </Box>
          <Spacer />
          <HStack display={{ base: "none", md: "flex" }}>
            <DesktopNav />
          </HStack>
          <Spacer />
        </Flex>

        <Stack
          flex={{ base: 1, md: 0 }}
          justify={"flex-end"}
          direction={"row"}
          spacing={6}
        >
          <Box
            as="a"
            href={"/login"}
            width="90px"
            height="40px"
            transition="all 0.2s cubic-bezier(.08,.52,.52,1)"
            // px="8px"
            pt={"8px"}
            borderRadius="25px"
            fontSize="14px"
            fontWeight="semibold"
            background={"hsla(0,0%,100%,0.2)"}
            color="#fff"
            _hover={{ bg: "hsla(0,0%,100%,0.4)" }}
            _active={{
              bg: "hsla(0,0%,100%,0.4)",
              transform: "scale(0.98)",
            }}
            _focus={{
              boxShadow:
                "0 0 1px 2px rgba(88, 144, 255, .75), 0 1px 1px rgba(0, 0, 0, .15)",
            }}
          >
            Sign in
          </Box>
        </Stack>
      </Flex>

      <Collapse in={isOpen} animateOpacity>
        <MobileNav />
      </Collapse>
    </Box>
  );
};

export default Navbar;
