import {
  Container,
  Flex,
  Box,
  Heading,
  Button,
  VStack,
  Wrap,
  WrapItem,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  Textarea,
} from "@chakra-ui/react";
import { MdPhone, MdLocationOn, MdOutlineEmail } from "react-icons/md";
import { BsPerson } from "react-icons/bs";
import bannerBg from "../../assets/triacore-hero-bg.png";
import emailjs from "@emailjs/browser";
import React, { useState } from "react";

const Contact = () => {
  const [success, setSuccess] = useState(null);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_84op4e2",
        "template_esvutmj",
        e.target,
        "user_K4DQFjNgm7nW1IPA2FcT0"
      )
      .then(
        (result) => {
          setSuccess(true);
        },
        (error) => {
          setSuccess(false);
        }
      );
  };

  return (
    <Container maxW="full" mt={0} centerContent overflow="hidden" py={100}>
      <Flex
        w={"full"}
        h={"100vh"}
        position={"absolute"}
        top={0}
        left={0}
        right={0}
        flex={1}
        zIndex={-99}
        backgroundImage={bannerBg}
        backgroundSize={"cover"}
        backgroundPosition={"center center"}
        clipPath={{
          base: "polygon(0 0, 100% 0, 100% 60%, 0 80%)",
          md: "polygon(0 0, 100% 0, 100% 30%, 0 50%)",
        }}
      />
      <Box
        pt={5}
        pb={5}
        px={5}
        min={"100vh"}
        maxW={"6xl"}
        margin={"auto"}
        zIndex={1}
        boxShadow={"2xl"}
        rounded={"25px"}
        bg={
          "linear-gradient(hsla(0,0%,100%,.4),hsla(0,0%,100%,.3) 25%,rgba(246,249,252,.3) 50%,rgba(246,249,252,.2) 60%)"
        }
      >
        {" "}
        <Flex>
          <Box
            bg="#fff"
            color="white"
            borderRadius="lg"
            p={{ sm: 5, md: 5, lg: 16 }}
          >
            <Box p={4}>
              <Wrap spacing={{ base: 20, sm: 3, md: 5, lg: 20 }}>
                <WrapItem>
                  <Box color="#0a2540">
                    <Heading>Contact</Heading>

                    <Box py={{ base: 5, sm: 5, md: 8, lg: 10 }}>
                      <VStack pl={0} spacing={3} alignItems="flex-start">
                        <Button
                          size="md"
                          height="48px"
                          width="200px"
                          variant="ghost"
                          color="#0a2540"
                          _hover={{ border: "2px solid #0a2540" }}
                          leftIcon={<MdPhone color="#635bff" size="20px" />}
                        >
                          +1 818 533 1507
                        </Button>
                        {/* <Button
                          size="md"
                          height="48px"
                          width="200px"
                          variant="ghost"
                          color="#0a2540"
                          _hover={{ border: "2px solid #0a2540" }}
                          leftIcon={<MdEmail color="#635bff" size="20px" />}
                        >
                          contact@triacore.com
                        </Button> */}
                        <Button
                          size="md"
                          height="48px"
                          width="200px"
                          variant="ghost"
                          color="#0a2540"
                          _hover={{ border: "2px solid #0a2540" }}
                          leftIcon={
                            <MdLocationOn color="#635bff" size="20px" />
                          }
                        >
                          Los Angeles, California
                        </Button>
                      </VStack>
                    </Box>
                    {/* <HStack
                      mt={{ lg: 10, md: 10 }}
                      spacing={5}
                      px={5}
                      alignItems="flex-start"
                    >
                      <IconButton
                        aria-label="facebook"
                        variant="ghost"
                        size="lg"
                        isRound={true}
                        _hover={{ bg: "#635bff", color: "#fff" }}
                        icon={<MdFacebook size="28px" />}
                      />
                      <IconButton
                        aria-label="github"
                        variant="ghost"
                        size="lg"
                        isRound={true}
                        _hover={{ bg: "#635bff", color: "#fff" }}
                        icon={<BsGithub size="28px" />}
                      />
                      <IconButton
                        aria-label="discord"
                        variant="ghost"
                        size="lg"
                        isRound={true}
                        _hover={{ bg: "#635bff", color: "#fff" }}
                        icon={<BsDiscord size="28px" />}
                      />
                    </HStack> */}
                  </Box>
                </WrapItem>
                <WrapItem>
                  <Box bg="white" borderRadius="lg">
                    <Box m={8} color="#0B0E3F">
                      <VStack spacing={5}>
                        <FormControl id="name">
                          <FormLabel>Your Name</FormLabel>
                          <InputGroup borderColor="#E0E1E7">
                            <InputLeftElement
                              pointerEvents="none"
                              children={<BsPerson color="gray.800" />}
                            />
                            <Input type="text" size="md" />
                          </InputGroup>
                        </FormControl>
                        <FormControl id="name">
                          <FormLabel>Mail</FormLabel>
                          <InputGroup borderColor="#E0E1E7">
                            <InputLeftElement
                              pointerEvents="none"
                              children={<MdOutlineEmail color="gray.800" />}
                            />
                            <Input type="text" size="md" />
                          </InputGroup>
                        </FormControl>
                        <FormControl id="name">
                          <FormLabel>Message</FormLabel>
                          <Textarea
                            borderColor="gray.300"
                            _hover={{
                              borderRadius: "gray.300",
                            }}
                            placeholder="message"
                          />
                        </FormControl>
                        <FormControl id="name" float="right">
                          <Button
                            variant="solid"
                            bg="#0a2540"
                            color="white"
                            _hover={{ bg: "#635bff" }}
                            onClick={() => sendEmail()}
                          >
                            Send Message
                          </Button>
                        </FormControl>
                      </VStack>
                    </Box>
                  </Box>
                </WrapItem>
              </Wrap>
            </Box>
          </Box>
        </Flex>
      </Box>
    </Container>
  );
};

export default Contact;
