import React from "react";
import {
  Box,
  VStack,
  Button,
  Flex,
  Divider,
  chakra,
  Grid,
  GridItem,
  Container,
  Text,
} from "@chakra-ui/react";
import {} from "@chakra-ui/react";

const Feature = ({ heading, text }) => {
  return (
    <GridItem>
      <chakra.h3 fontSize="xl" fontWeight="600">
        {heading}
      </chakra.h3>
      <chakra.p>{text}</chakra.p>
    </GridItem>
  );
};

const CallToAction = () => {
  return (
    <Box as={Container} maxW="6xl" mt={14} pt={150} pb={100}>
      <Grid
        templateColumns={{
          base: "repeat(1, 1fr)",
          sm: "repeat(2, 1fr)",
          md: "repeat(2, 1fr)",
        }}
        gap={4}
      >
        <GridItem colSpan={1}>
          <VStack alignItems="flex-start" spacing="20px">
            <chakra.h2 fontSize="3xl" fontWeight="700" color={"#0a2540"}>
              Let's have a chat
            </chakra.h2>
            <Box
              as="a"
              href={"/contact"}
              width="120px"
              height="40px"
              lineHeight="1.2"
              transition="all 0.2s cubic-bezier(.08,.52,.52,1)"
              pt="12px"
              borderRadius="25px"
              fontSize="14px"
              fontWeight="semibold"
              bg="#0a2540"
              color="#fff"
              _hover={{ bg: "#0a2540b7" }}
              _active={{
                bg: "hsla(0,0%,100%,0.4)",
                transform: "scale(0.98)",
              }}
              _focus={{
                boxShadow:
                  "0 0 1px 2px rgba(88, 144, 255, .75), 0 1px 1px rgba(0, 0, 0, .15)",
              }}
            >
              Get Started
            </Box>
          </VStack>
        </GridItem>
        <GridItem>
          <Flex>
            <Text
              fontSize={"lg"}
              textAlign={{ base: "center", sm: "center", lg: "left" }}
              color={"#0a2540"}
            >
              Send us a message and we will reach you immediately while we take
              our clients on their journey to success.
            </Text>
          </Flex>
        </GridItem>
      </Grid>
      <Divider mt={12} mb={12} />
      <Grid
        templateColumns={{
          base: "repeat(1, 1fr)",
          sm: "repeat(2, 1fr)",
          md: "repeat(4, 1fr)",
        }}
        gap={{ base: "8", sm: "12", md: "16" }}
      >
        <Feature heading={"Build"} text={"Help you build something"} />
        <Feature heading={"Collaborate"} text={"Collaborate ideas together"} />
        <Feature
          heading={"Personalize"}
          text={"Personalize a solution for your business"}
        />
        <Feature heading={"Consult"} text={"Look for options"} />
      </Grid>
    </Box>
  );
};

export default CallToAction;
