export const heroDetails = [
  {
    home: {
      bgImg: require("../assets/triacore-hero-bg.png"),
      topHeading: "We Deliver Results",
      mainHeading: "We craft your dreams into reality.",
      caption:
        "From sleek to classic designs, website maintenance, software and mobile apps we got everything your business needs.",
      buttonText: "Get Started",
      bannerImg: require("../assets/triacore-banner-img.png"),
    },
    web: {
      bgImg: require("../assets/triacore-hero-bg.png"),
      topHeading: "Unique Experiences",
      mainHeading: "Stand out from the crowd.",
      caption:
        "Let us build an engaging, informative, and sales converting e-commerce hub for your business.",
      buttonText: "Get Started",
      bannerImg: require("../assets/triacore-web-dev.png"),
    },
    app: {
      bgImg: require("../assets/triacore-hero-bg.png"),
      topHeading: "App Development",
      mainHeading: "From idea to execution.",
      caption:
        "We will develop a smart, cohesive, and engaging native or hybrid app for your business.",
      bannerImg: require("../assets/triacore-app-dev.png"),
      buttonText: "Get Started",
    },
    company: {
      bgImg: require("../assets/triacore-hero-bg.png"),
      topHeading: "Triacore",
      mainHeading: "We are Triacore",
      caption:
        "We build unique and compelling customer experiences for your brand.",
      bannerImg: require("../assets/triacore-company-img.png"),
      buttonText: "Get Started",
    },
    privacy: {
      bgImg: require("../assets/triacore-hero-bg.png"),
      topHeading: "Triacore",
      mainHeading: "Privacy Policy",
      caption:
        "Last updated: February 3, 2022. This Privacy Policy includes important information about your personal data and we encourage you to read it carefully. ",
      bannerImg: require("../assets/triacore-company-img.png"),
      buttonText: "Contact Us",
    },
  },
];

export const companyDetails = {
  desc: "In today’s world, it’s vital to your brand to give your customers a shopping experience that is fluid, on brand, and unique. Triacore can do this for you. We offer software and site experiences that speak to your customers, represent your brand and drive results. We help to turn visitors into shoppers and shoppers into repeat, loyal customers. We started this business in 2015 with the idea of offering high-quality software and services for a better price. And our fully virtual experience allows us to do just that. Triacore offers three core services: site design, strategy, and technology, and we hope that you will “try a core” service. Together we will work with you to define your business needs and goals and find the service that will benefit your business the most.",
  services: [
    { id: 1, service: "Web Development" },
    { id: 2, service: "Mobile App Development" },
    { id: 3, service: "Booking System" },
    { id: 4, service: "Point of Sale" },
    { id: 5, service: "Human Resource Information System" },
    { id: 6, service: "Payroll System" },
    { id: 7, service: "Inventory System" },
    { id: 8, service: "Warehouse Management System" },
  ],
};

export const clients = [
  {
    id: 0,
    img: require("../assets/dhc.png"),
  },
  {
    id: 1,
    img: require("../assets/vellora.png"),
  },
  {
    id: 2,
    img: require("../assets/iarki.png"),
  },
  {
    id: 3,
    img: require("../assets/chiescakes.png"),
  },
  {
    id: 4,
    img: require("../assets/vmctravel.png"),
  },
];

export const homeSection = {
  name: "home",
  topHeading: "Make the most in every click",
  mainHeading: "Success is within your hands",
  desc: "At Triacore, we are committed to give you the best outlook available on site. Tell us how you want to be seen and we will build your brand which you will be best remembered for. Join the team and connect with the world in a whole new level.",
  sectionImg: require("../assets/triacore-home-img.png"),
};

export const webdev = [
  {
    feature: [
      {
        id: "ecom",
        img: require("../assets/triacore-ecom-img.png"),
        heading: "E-Commerce Hub",
        caption:
          "Let us build an engaging, informative, and sales converting e-commerce hub for your business.",
        buttonText: "Read more",
        name: "ecom",
        sectionImg: require("../assets/triacore-ecom-img.png"),
        topHeading: "Building Your E-Commerce Hub",
        mainHeading:
          "Let us build an engaging, informative, and sales converting e-commerce hub for your business.",
        desc: "Your website is the digital face of your brand. You want it to be an all-encompassing, warm, and welcoming place for your visitors and customers to land. It needs to be more than just an informational hub where you sell your product. Your website should seamlessly represent your brand, community, and e-commerce shop. And that’s exactly what we can provide for you. We’ll build more than just an e-commerce site for your business. We’ll build an e-commerce experience where visitors will turn into shoppers and return again and again as loyal buyers.",
      },
      {
        id: "custom",
        img: require("../assets/triacore-unique-img.png"),
        heading: "Provide Your Visitors a Unique Experience",
        caption:
          "Customers expect a great buying experience and we can deliver that with a customized program unique to your site.",
        buttonText: "Read more",
        name: "custom",
        sectionImg: require("../assets/triacore-custom-img.png"),
        topHeading: "Provide Your Visitors a Unique Experience",
        mainHeading:
          "Customers expect a great buying experience and we can deliver that with a customized program unique to your site.",
        desc: "In an online world where every site is very similar, customers are looking to you to be unique and customized. Providing your visitors with a customized experience is proven to increase spend and customer loyalty. We work with our clients to launch customization programs that will make their sites stand out. They will appear unique and different on the front end but be easy to manage on the back end. These customization programs will convert your visitors into loyal, returning buyers.",
      },
      {
        id: "omni",
        img: require("../assets/triacore-consistent-img.png"),
        heading: "Consistent and continuous.",
        caption:
          "As an online shopper yourself you know that today’s shopping experience is typically not all on one channel.",
        buttonText: "Read more",
        name: "omni",
        sectionImg: require("../assets/triacore-consistent-img.png"),
        topHeading: "Implement an Omnichannel Strategy",
        mainHeading:
          "Our omnichannel strategy will ensure that you deliver a shopping journey that is consistent and continuous.",
        desc: "As an online shopper yourself you know that today’s shopping experience is typically not all on one channel. With buyers being served ads in different places and shopping from different devices, their experiences are all over the place. But, no matter their shopping journey, your job is to make sure that you are meeting their needs and expectations in every environment. It’s vital to your band to make sure that your message and identity are consistent on all channels. And we can help you do that with our omnichannel strategy. Our omnichannel strategy will take a unique look at your customer base and how they are currently shopping. With that information we’ll make sure that each step of their journey is meaningful. By taking a look at each channel and device, we’ll leverage their unique benefits and deliver an omnichannel strategy that is consistent and seamless.",
      },
    ],
  },
];

export const appdev = [
  {
    id: "software",
    sectionImg: require("../assets/triacore-banner-img.png"),
    mainHeading: "Application Development",
    desc: "Triacore’s software and mobile app development team will make sure that you stand out from the crowd with compelling and converting software.The number of shoppers on mobile is growing at an exponential pace, so let us help you be there first with a unique experience for your customers. Our Mobile App Development team will be sure that your app is on brand and a fluid shopping experience from your e-commerce site.",
    buttonText: "Get Started",
  },
  {
    id: "native",
    sectionImg: require("../assets/triacore-unique-img.png"),
    mainHeading: "Native App",
    desc: "Whether you are looking for an iOS or Android native app, Triacore can deliver an expertly designed and cohesive app for your business. Our team will guide you along the way from start to finish. We’ll work with you to develop a product strategy that is on brand and continue the process until your app is available to consumers.",
    buttonText: "Get Started",
  },
  {
    id: "hybrid",
    sectionImg: require("../assets/triacore-consistent-img.png"),
    mainHeading: "Hybrid App",
    desc: "If a hybrid app is what works for your business, we can build that for you too. We’ll make sure that your hybrid app is fully integrated with your website so that users will experience a familiar and easy shopping experience.",
    buttonText: "Get Started",
  },
];

export const avatars = [
  {
    name: "Ryan Florence",
    url: "https://bit.ly/ryan-florence",
  },
  {
    name: "Segun Adebayo",
    url: "https://bit.ly/sage-adebayo",
  },
  {
    name: "Kent Dodds",
    url: "https://bit.ly/kent-c-dodds",
  },
  {
    name: "Prosper Otemuyiwa",
    url: "https://bit.ly/prosper-baba",
  },
  {
    name: "Christian Nwamba",
    url: "https://bit.ly/code-beast",
  },
];
