import React from "react";
import {
  Container,
  SimpleGrid,
  Image,
  Flex,
  Heading,
  Text,
  Stack,
  StackDivider,
  Icon,
  useColorModeValue,
} from "@chakra-ui/react";
import { CheckIcon } from "@chakra-ui/icons";

const Feature = ({ text, icon, iconBg }) => {
  return (
    <Stack direction={"row"} align={"center"}>
      <Flex
        w={8}
        h={8}
        align={"center"}
        justify={"center"}
        rounded={"full"}
        bg={iconBg}
      >
        {icon}
      </Flex>
      <Text fontWeight={600}>{text}</Text>
    </Stack>
  );
};

const Section = (section) => {
  return (
    <Flex
      background={`${section.section.name === "home" ? "#f6f9fc" : "#fff"}`}
      pb={20}
      pt={40}
    >
      <Container maxW={"6xl"}>
        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={20}>
          <Stack spacing={4} display={"flex"} justifyContent={"center"}>
            <Text
              color={"#635bff"}
              fontWeight={600}
              fontSize={"md"}
              textAlign={{ base: "center", sm: "center", lg: "left" }}
              lineHeight={"5px"}
            >
              {section.section.topHeading}
            </Text>
            <Heading
              textAlign={{ base: "center", sm: "center", lg: "left" }}
              fontSize={"4xl"}
              fontWeight={700}
              lineHeight={"50px"}
              color={"#0a2540"}
            >
              {section.section.mainHeading}
            </Heading>
            <Text
              fontSize={"xl"}
              textAlign={{ base: "center", sm: "center", lg: "left" }}
              color={"#0a2540"}
            >
              {section.section.desc}
            </Text>
            {section.section.name === "home" ? (
              <Stack
                spacing={4}
                divider={<StackDivider borderColor={"gray.200"} />}
              >
                <Feature
                  icon={<Icon as={CheckIcon} color={"#fff"} w={5} h={5} />}
                  iconBg={"#635bff"}
                  text={"Digital Marketing"}
                />
                <Feature
                  icon={<Icon as={CheckIcon} color={"#fff"} w={5} h={5} />}
                  iconBg={"#635bff"}
                  text={"Omnichannel"}
                />
                <Feature
                  icon={<Icon as={CheckIcon} color={"#fff"} w={5} h={5} />}
                  iconBg={"#635bff"}
                  text={"Product Customization"}
                />
              </Stack>
            ) : null}
          </Stack>
          <Flex align={"center"} justify={"center"}>
            <Image
              transform={"scaleX(-1)"}
              maxH={600}
              alt={"feature image"}
              src={section.section.sectionImg}
              objectFit={"contain"}
            />
          </Flex>
        </SimpleGrid>
      </Container>
    </Flex>
  );
};
export default Section;
