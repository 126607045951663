import {
  Box,
  Container,
  Heading,
  SimpleGrid,
  Icon,
  Text,
  Stack,
  HStack,
  VStack,
} from "@chakra-ui/react";
import { CheckIcon } from "@chakra-ui/icons";

// Replace test data with your own
const features = Array.apply(null, Array(8)).map(function (x, i) {
  return {
    id: i,
    title: "Lorem ipsum dolor sit amet",
    text: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam.",
  };
});

const GridListWithHeading = (items) => {
  return (
    <Box p={4}>
      <Stack spacing={4} as={Container} maxW={"6xl"} textAlign={"center"}>
        <Text color={"gray.600"} fontSize={"lg"}>
          {items.items.desc}
        </Text>
      </Stack>

      <Container maxW={"6xl"} mt={10}>
        <SimpleGrid columns={{ base: 1, md: 2, lg: 4 }} spacing={10}>
          {items.items.services.map((service) => (
            <HStack key={service.id} align={"top"}>
              <Box color={"#635bff"} px={2}>
                <Icon as={CheckIcon} />
              </Box>
              <VStack
                align={"start"}
                textAlign={{ base: "center", sm: "left", md: "left" }}
              >
                <Text fontWeight={600}>{service.service}</Text>
              </VStack>
            </HStack>
          ))}
        </SimpleGrid>
      </Container>
    </Box>
  );
};

export default GridListWithHeading;
