import React from "react";
import {
  Box,
  Flex,
  Divider,
  Heading,
  List,
  ListIcon,
  ListItem,
  Stack,
  Text,
} from "@chakra-ui/react";
import { FaCheckCircle } from "react-icons/fa";
import bannerBg from "../../assets/triacore-hero-bg.png";

const optionOne = [
  {
    id: 1,
    desc: "5GB",
  },
  {
    id: 2,
    desc: "7GB Bandwidth",
  },
  {
    id: 3,
    desc: "cPanel",
  },
];
const optionTwo = [
  {
    id: 1,
    desc: "20GB",
  },
  {
    id: 2,
    desc: "Unli Bandwidth",
  },
  {
    id: 3,
    desc: "cPanel",
  },
];
const optionThree = [
  {
    id: 1,
    desc: "50GB",
  },
  {
    id: 2,
    desc: "Unli Bandwidth",
  },
  {
    id: 3,
    desc: "cPanel",
  },
];
interface PackageTierProps {
  title: string;
  options: Array<{ id: number, desc: string }>;
  typePlan: string;
  checked?: boolean;
}

const PackageTier = ({ title, options, typePlan, checked = false }) => {
  const colorTextLight = checked ? "white" : "purple.600";
  const bgColorLight = checked ? "purple.400" : "gray.300";

  const colorTextDark = checked ? "white" : "purple.500";
  const bgColorDark = checked ? "purple.400" : "gray.300";

  return (
    <Stack
      p={3}
      py={3}
      justifyContent={{
        base: "flex-start",
        md: "space-around",
      }}
      direction={{
        base: "column",
        md: "row",
      }}
      alignItems={{ md: "center" }}
    >
      <Heading size={"md"} color={"#0a2540"}>
        {title}
      </Heading>
      <List
        spacing={3}
        textAlign={{ base: "center", sm: "center", lg: "left" }}
      >
        {options.map((desc, id) => (
          <ListItem key={desc.id} color={"#0a2540"}>
            <ListIcon as={FaCheckCircle} color="#635bff" />
            {desc.desc}
          </ListItem>
        ))}
      </List>
      <Heading size={"xl"} color={"#0a2540"}>
        {typePlan}
      </Heading>
      <Stack>
        <Box
          as="a"
          href={"/contact"}
          width="120px"
          height="40px"
          lineHeight="1.2"
          transition="all 0.2s cubic-bezier(.08,.52,.52,1)"
          pt="12px"
          borderRadius="25px"
          fontSize="14px"
          fontWeight="semibold"
          bg="#0a2540"
          color="#fff"
          _hover={{ bg: "#0a2540b7" }}
          _active={{
            bg: "hsla(0,0%,100%,0.4)",
            transform: "scale(0.98)",
          }}
          _focus={{
            boxShadow:
              "0 0 1px 2px rgba(88, 144, 255, .75), 0 1px 1px rgba(0, 0, 0, .15)",
          }}
        >
          Get Started
        </Box>
      </Stack>
    </Stack>
  );
};

const Pricing = () => {
  return (
    <Flex bg={"linear-gradient(180deg, #f6f9fc 50%, #fff 50%)"}>
      <Flex
        w={"full"}
        h={"800px"}
        position={"absolute"}
        backgroundImage={bannerBg}
        backgroundSize={"cover"}
        backgroundPosition={"center center"}
        clipPath={"polygon(0 30%, 100% 0, 100% 75%, 0 100%)"}
        zIndex={0}
      />
      <Box
        pt={10}
        pb={5}
        px={5}
        min={"100vh"}
        maxW={"6xl"}
        margin={"auto"}
        zIndex={1}
        boxShadow={"2xl"}
        rounded={"25px"}
        bg={
          "linear-gradient(hsla(0,0%,100%,.4),hsla(0,0%,100%,.3) 25%,rgba(246,249,252,.3) 50%,rgba(246,249,252,.2) 60%)"
        }
      >
        <Stack
          spacing={4}
          width={"100%"}
          direction={"column"}
          bg={"#fff"}
          rounded={"25px"}
        >
          <Stack
            p={5}
            alignItems={"center"}
            justifyContent={{
              base: "flex-start",
              md: "space-around",
            }}
            direction={{
              base: "column",
              md: "row",
            }}
          >
            <Stack
              width={{
                base: "100%",
                md: "40%",
              }}
              textAlign={{ base: "center", sm: "center", lg: "left" }}
            >
              <Heading size={"lg"} color={"#0a2540"}>
                The Right Plan for <Text color="#635bff">Your Business</Text>
              </Heading>
            </Stack>
            <Stack
              width={{
                base: "100%",
                md: "60%",
              }}
            >
              <Text textAlign={{ base: "center", sm: "center", lg: "left" }}>
                We will bring your brand to places where it should be, reaching
                people wherever they are, and leaving a mark that will create a
                great impact.
              </Text>
            </Stack>
          </Stack>
          <Divider />
          <PackageTier title={"Starter"} typePlan="$9.99" options={optionOne} />
          <Divider />
          <PackageTier
            title={"Business"}
            checked={true}
            typePlan="$20.00"
            options={optionTwo}
          />
          <Divider />
          <PackageTier
            title={"Portal"}
            typePlan="$50.00"
            options={optionThree}
          />
        </Stack>
      </Box>
    </Flex>
  );
};

export default Pricing;
