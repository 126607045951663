import React from "react";
import Hero from "../../components/Hero/Hero";
import CustomSection from "../../components/Section/CustomSection";
import { appdev, heroDetails } from "../../data/data";
import { Box } from "@chakra-ui/react";
import CallToAction from "../../components/CalltoAction/CallToAction";

const AppDev = () => {
  return (
    <Box>
      <Hero items={heroDetails[0].app} />
      <CustomSection items={appdev[0]} />
      <CallToAction />
    </Box>
  );
};

export default AppDev;
