import React from "react";
import {
  Stack,
  Box,
  Flex,
  Text,
  Heading,
  Image,
  Container,
  Icon,
} from "@chakra-ui/react";

const Hero = (items) => {
  return (
    <>
      <Flex
        w={"full"}
        h={"100vh"}
        position={"absolute"}
        top={0}
        left={0}
        right={0}
        flex={1}
        backgroundImage={items.items.bgImg}
        backgroundSize={"cover"}
        backgroundPosition={"center center"}
        clipPath={{
          base: "polygon(0 0, 100% 0, 100% 60%, 0 80%)",
          md: "polygon(0 0, 100% 0, 100% 30%, 0 50%)",
        }}
        zIndex={-99}
      />
      <Container maxW={"6xl"}>
        <Stack
          align={"center"}
          spacing={{ base: 8, md: 10 }}
          py={{ base: 20, md: 28 }}
          direction={{ base: "column", md: "row" }}
        >
          <Stack flex={1} spacing={{ base: 5, md: 10 }}>
            <Heading
              lineHeight={1.1}
              fontWeight={600}
              fontSize={{ base: "3xl", sm: "4xl", lg: "6xl" }}
              textAlign={{ base: "center", sm: "left", lg: "left" }}
              zIndex={-1}
            >
              <Text
                as={"span"}
                position={"relative"}
                color={"white"}
                fontWeight={600}
                fontSize={"sm"}
                bg={"#0a254012"}
                p={2}
                rounded={"25px"}
                width={"150px"}
              >
                {items.items.topHeading}
              </Text>
              <br />
              <Text
                as={"span"}
                position={"relative"}
                color={"#0a2540"}
                fontSize={{ base: "7xl", md: "8xl" }}
                letterSpacing={"-6px"}
                lineHeight={"70px"}
                // _after={{
                //   content: "''",
                //   width: "full",
                //   height: "30%",
                //   position: "absolute",
                //   bottom: 1,
                //   left: 0,
                //   bg: "hsla(0,0%,100%,0.4)",
                //   zIndex: -1,
                // }}
                fontWeight={"bold"}
              >
                {items.items.mainHeading}
              </Text>
            </Heading>
            <Text
              color={"#0a2540"}
              textAlign={{ base: "center", sm: "left", lg: "left" }}
              fontSize={"xl"}
              fontWeight={600}
            >
              {items.items.caption}
            </Text>
            <Stack
              spacing={{ base: 4, sm: 6 }}
              direction={{ base: "column", sm: "row" }}
              justifyContent={{ base: "center", sm: "left", lg: "left" }}
            >
              <Box
                as="a"
                href={"/contact"}
                width="120px"
                height="40px"
                lineHeight="1.2"
                transition="all 0.2s cubic-bezier(.08,.52,.52,1)"
                pt={"12px"}
                borderRadius="25px"
                fontSize="14px"
                fontWeight="semibold"
                bg="#0a2540"
                color="#fff"
                _hover={{ bg: "#0a2540b7" }}
                _active={{
                  bg: "hsla(0,0%,100%,0.4)",
                  transform: "scale(0.98)",
                }}
                _focus={{
                  boxShadow:
                    "0 0 1px 2px rgba(88, 144, 255, .75), 0 1px 1px rgba(0, 0, 0, .15)",
                }}
              >
                {items.items.buttonText}
              </Box>
            </Stack>
          </Stack>
          <Flex
            flex={1}
            justify={"center"}
            align={"center"}
            position={"relative"}
            w={"full"}
          >
            {/* <Blob
              w={"150%"}
              h={"150%"}
              position={"absolute"}
              top={"-20%"}
              left={0}
              zIndex={0}
              color={"hsla(0,0%,100%,.3)"}
              clipPath={"text"}
            /> */}
            <Box
              position={"relative"}
              height={"auto"}
              width={"100%"}
              overflow={"hidden"}
              // rounded={"50px"}
              // boxShadow={"2xl"}
              // background={
              //   "linear-gradient(hsla(0,0%,100%,.4),hsla(0,0%,100%,.3) 25%,rgba(246,249,252,.3) 50%,#f6f9fc 60%)"
              // }
            >
              <Image
                alt={"Hero Image"}
                fit={"cover"}
                align={"center"}
                w={"100%"}
                h={"100%"}
                transform={"scaleX(-1)"}
                src={items.items.bannerImg}
              />
            </Box>
          </Flex>
        </Stack>
      </Container>
    </>
  );
};

export const Blob = (props: IconProps) => {
  return (
    <Icon
      width={"100%"}
      viewBox="0 0 578 440"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M239.184 439.443c-55.13-5.419-110.241-21.365-151.074-58.767C42.307 338.722-7.478 282.729.938 221.217c8.433-61.644 78.896-91.048 126.871-130.712 34.337-28.388 70.198-51.348 112.004-66.78C282.34 8.024 325.382-3.369 370.518.904c54.019 5.115 112.774 10.886 150.881 49.482 39.916 40.427 49.421 100.753 53.385 157.402 4.13 59.015 11.255 128.44-30.444 170.44-41.383 41.683-111.6 19.106-169.213 30.663-46.68 9.364-88.56 35.21-135.943 30.551z"
        fill="currentColor"
      />
    </Icon>
  );
};

export default Hero;
