import { Box, Image, Flex } from "@chakra-ui/react";
import React from "react";
import CallToAction from "../../components/CalltoAction/CallToAction";
import Hero from "../../components/Hero/Hero";
import Pricing from "../../components/Pricing/Pricing";
import Section from "../../components/Section/Section";
import { clients, homeSection } from "../../data/data";
import { heroDetails } from "../../data/data";

const Home = () => {
  console.log(homeSection);
  return (
    <Box>
      <Hero items={heroDetails[0].home} />
      <Flex
        display={{ base: "none", md: "flex" }}
        justify={"space-between"}
        align={"center"}
        maxW={"6xl"}
        margin={"auto"}
        py={20}
      >
        {clients.map((client, key) => (
          <Box p={4}>
            <Image src={client.img} />
          </Box>
        ))}
      </Flex>
      <Section section={homeSection} />
      <Pricing />
      <CallToAction />
    </Box>
  );
};

export default Home;
