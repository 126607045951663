import {
  Flex,
  Container,
  Heading,
  Stack,
  Text,
  Box,
  Image,
  Icon,
  useColorModeValue,
} from "@chakra-ui/react";

export const Blob = (props) => {
  return (
    <Icon
      width={"100%"}
      viewBox="0 0 578 440"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M239.184 439.443c-55.13-5.419-110.241-21.365-151.074-58.767C42.307 338.722-7.478 282.729.938 221.217c8.433-61.644 78.896-91.048 126.871-130.712 34.337-28.388 70.198-51.348 112.004-66.78C282.34 8.024 325.382-3.369 370.518.904c54.019 5.115 112.774 10.886 150.881 49.482 39.916 40.427 49.421 100.753 53.385 157.402 4.13 59.015 11.255 128.44-30.444 170.44-41.383 41.683-111.6 19.106-169.213 30.663-46.68 9.364-88.56 35.21-135.943 30.551z"
        fill="currentColor"
      />
    </Icon>
  );
};

const CustomSection = (items) => {
  return (
    <Container maxW={"6xl"}>
      <Stack
        textAlign={"center"}
        align={"center"}
        spacing={{ base: 8, md: 10 }}
        py={{ base: 20, md: 28 }}
      >
        <Heading
          fontWeight={700}
          fontSize={{ base: "3xl", sm: "4xl", md: "6xl" }}
          lineHeight={"110%"}
          color={"#0a2540"}
        >
          {items.items.mainHeading}
        </Heading>
        <Text color={"gray.500"} maxW={"3xl"}>
          {items.items.desc}
        </Text>
        <Stack spacing={6} direction={"row"}>
          <Box
            as="button"
            href={"#"}
            width="120px"
            height="40px"
            lineHeight="1.2"
            transition="all 0.2s cubic-bezier(.08,.52,.52,1)"
            px="8px"
            borderRadius="25px"
            fontSize="14px"
            fontWeight="semibold"
            bg="#0a2540"
            color="#fff"
            _hover={{ bg: "#0a2540b7" }}
            _active={{
              bg: "hsla(0,0%,100%,0.4)",
              transform: "scale(0.98)",
            }}
            _focus={{
              boxShadow:
                "0 0 1px 2px rgba(88, 144, 255, .75), 0 1px 1px rgba(0, 0, 0, .15)",
            }}
          >
            {items.items.buttonText}
          </Box>
        </Stack>
        <Flex w={"full"} justify={"center"}>
          <Blob
            w={"50%"}
            h={"20%"}
            position={"absolute"}
            top={{ base: "35%", md: "40%" }}
            left={0}
            zIndex={-1}
            color={"purple.50"}
          />
          <Image
            alt={"Hero Image"}
            fit={"cover"}
            align={"center"}
            src={items.items.sectionImg}
          />
        </Flex>
      </Stack>
    </Container>
  );
};

export default CustomSection;
