import React from "react";
import {
  Box,
  SimpleGrid,
  Icon,
  Text,
  Stack,
  Flex,
  Image,
} from "@chakra-ui/react";
import { useState } from "react";
import Section from "../Section/Section";

const Feature = (items) => {
  const [selected, setSelected] = useState("");

  const handleClick = (item) => {
    setSelected(item);
  };

  return (
    <Box maxW={"6xl"} margin={"auto"}>
      <SimpleGrid columns={{ base: 1, md: 3 }} spacing={10}>
        {items.items.feature.map((item, id) => (
          <Stack>
            <Flex
              align={"center"}
              justify={"center"}
              color={"white"}
              rounded={"full"}
              bg={"gray.100"}
              mb={1}
            >
              <Image src={item.img} alt={item.id} maxH={350} />
            </Flex>
            <Text fontWeight={600}>{item.heading}</Text>
            <Text color={"gray.600"}>{item.caption}</Text>
            <Flex align={"center"} justify={"center"}>
              <Box
                as="button"
                onClick={() => handleClick(item)}
                width="120px"
                height="40px"
                lineHeight="1.2"
                transition="all 0.2s cubic-bezier(.08,.52,.52,1)"
                px="8px"
                borderRadius="25px"
                fontSize="14px"
                fontWeight="semibold"
                bg="#0a2540"
                color="#fff"
                _hover={{ bg: "#0a2540b7" }}
                _active={{
                  bg: "hsla(0,0%,100%,0.4)",
                  transform: "scale(0.98)",
                }}
                _focus={{
                  boxShadow:
                    "0 0 1px 2px rgba(88, 144, 255, .75), 0 1px 1px rgba(0, 0, 0, .15)",
                }}
              >
                {item.buttonText}
              </Box>
            </Flex>
          </Stack>
        ))}
      </SimpleGrid>
      {!selected ? null : <Section section={selected} />}
    </Box>
  );
};

export default Feature;
